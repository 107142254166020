import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import { toExportFormat } from "@/utils/export";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultColumns = [{
      title: "SKU",
      show: true,
      name: "sku",
      width: 100,
      filter: "text"
    }, {
      title: "Товар",
      show: true,
      name: "product",
      type: "text",
      width: 300,
      getImage: item => item.productImage
    }, {
      title: "Скрипт",
      show: true,
      name: "script_name",
      width: 160,
      filter: "text"
    }, {
      title: "Диапазон цен",
      show: true,
      name: "price_range",
      type: "text",
      width: 160
    }];
    return {
      columns: defaultColumns,
      defaultColumns
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    onRowClick(item) {
      if (item.sku && item.script_name) {
        this.$router.push({
          name: "RepricerEventsLog",
          params: {
            product: item.sku,
            script: item.script_name
          }
        });
      }
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      this.columns = this.defaultColumns;
      let result = await this.$store.dispatch("repricer/getProductsManagement", {
        ...query,
        ...this.reportSettings
      });
      if (result.data.length > 0) {
        var _result$data$;
        const datesColumns = Object.keys((_result$data$ = result.data[0]) === null || _result$data$ === void 0 ? void 0 : _result$data$.price_by_day_List).sort().map(key => {
          return {
            title: key,
            show: true,
            name: key,
            type: "text",
            width: 140,
            noSettings: true,
            sort: false
          };
        });
        result.data = result.data.map(item => {
          return {
            ...item,
            productImage: `https://salesfinder.ru/api/getProductImage?mp=${this.reportSettings.mp}&sku=${item.sku}`,
            ...item.price_by_day_List
          };
        });
        this.columns = [...this.columns, ...datesColumns];
      }
      if (query.action === "export") {
        return toExportFormat(result, this.columns);
      } else {
        result = {
          items: [...result.data],
          total: result.totals.total,
          success: result.success,
          message: result.message
        };
      }
      return result;
    }
  }
};