import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('WarningRepricer'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.limit ? _c('div', {
          staticStyle: {
            "margin-top": "10px",
            "margin-right": "10px"
          }
        }, [_c('span', {
          staticClass: "limit"
        }, [_vm._v(" Кол-во SKU под управлением Репрайсера: ")]), _c('span', {
          staticClass: "limit",
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.limit.count) + " из " + _vm._s(_vm.limit.limit) + " доступных ")])]) : _vm._e(), _c('button', {
          staticClass: "btn-outline btn-fit",
          staticStyle: {
            "margin-right": "10px"
          },
          on: {
            "click": _vm.onAddRepriceLimit
          }
        }, [_vm._v(" Увеличить лимит ")]), _c('button', {
          staticClass: "btn-outline btn-fit",
          staticStyle: {
            "margin-right": "10px"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'Connections'
              });
            }
          }
        }, [_vm._v(" Управление магазинами "), _c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/edit.svg`),
            "width": "19",
            "height": "18"
          }
        })]), _vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2535027625)
  }, [_vm._v(" Репрайсер - Обзор показателей ")]), _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.$store.state.repricer.calendar,
      "noDynamic": true
    }
  }), _c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('button', {
          staticClass: "btn-outline",
          staticStyle: {
            "width": "150px"
          },
          attrs: {
            "disabled": _vm.freezeSubscription
          },
          on: {
            "click": _vm.addScript
          }
        }, [_vm._v(" + Добавить новый ")])];
      },
      proxy: true
    }], null, false, 3901748494)
  }, [_vm._v(" Сценарии изменения цен ")]), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettingsNoDates ? _c('scripts-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettingsNoDates,
      "configurable": true,
      "freezeSubscription": _vm.freezeSubscription
    },
    on: {
      "updateTotal": _vm.updateTotal,
      "addScript": _vm.addScript
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-scripts btn-table",
    attrs: {
      "disabled": _vm.freezeSubscription
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'RepricerScripts'
        });
      }
    }
  }, [_vm._v(" Все сценарии "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })]), _c('main-title', [_vm._v(" Мои товары ")]), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettingsNoDates ? _c('my-products-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettingsNoDates,
      "selectable": false,
      "paginatable": false,
      "configurable": true
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-table",
    attrs: {
      "disabled": _vm.freezeSubscription
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'RepricerProducts'
        });
      }
    }
  }, [_vm._v(" Все товары "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })]), _c('main-title', [_vm._v(" Товары под управлением - динамика цен ")]), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettingsNoDates ? _c('products-management-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettingsNoDates,
      "selectable": false,
      "paginatable": false,
      "configurable": true
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-table",
    attrs: {
      "disabled": _vm.freezeSubscription
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'RepricerProducts'
        });
      }
    }
  }, [_vm._v(" Полный отчет "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })]), _c('main-title', [_vm._v(" Лог событий ")]), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettingsNoDates ? _c('events-log-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettingsNoDates,
      "selectable": false,
      "paginatable": false,
      "configurable": true
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-outline btn-table",
    attrs: {
      "disabled": _vm.freezeSubscription
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'RepricerEventsLog'
        });
      }
    }
  }, [_vm._v(" Все события "), _c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/arrow.svg`),
      "width": "19",
      "height": "18"
    }
  })])], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };